// Bootstrap


// $blue
// $indigo
// $purple
// $pink
// $red
// $orange
// $yellow
// $green
// $teal
// $cyan
//$light: #e6e6e6;
//$white: #fafafa;
//$black: #050505;
//$dark: #242424;
//
////$primary: #2e7d32;
//$primary: #13863c;
//$secondary: #a6a6a6;
//$success: #2e7d32;
//$danger: #C80000;
//$warning: #CC9900;
//$info: #401ed7;

$white: #ffffff;

//$theme-colors: (
//    "light":      #fbfbfb,
//    "dark":       #252525,
//    "primary":    #1166f1,
//    "secondary":  #b23bfd,
//    "info":       #38c0ed,
//    "accent1":    #464e9e,
//    "accent2":    #e147ae,
//    "accent3":    #9fd8d1,
//    "success":    #05b669,
//    "warning":    #e7d005,
//    "danger":     #f91a02,
//);

//$body-bg: $dark;
//$body-color: $white;
$font-family-base: 'Noto Sans Georgian', 'Noto Sans', sans-serif;
//$modal-content-bg: var(--bs-dark);
//$modal-backdrop-bg: $dark;
//$accordion-button-active-bg: $primary;
//$accordion-button-active-color: $white;
//$accordion-button-bg: $secondary;
$input-focus-box-shadow: none !important;
$btn-focus-box-shadow: none !important;
$btn-active-box-shadow: none !important;
$form-select-focus-box-shadow: none !important;
$pagination-focus-box-shadow: none !important;
$input-btn-focus-box-shadow: none !important;
$input-placeholder-color: var(--bs-gray-500);
//$form-check-input-border:  1px solid $white;
