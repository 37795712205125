// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100;400;700&family=Noto+Sans:wght@400;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: $dark;
}

::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 20px;
    border: 2px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: $light;
}

@keyframes breath {
    0%, 100% {
        opacity: 1;
        color: var(--bs-dark);
    }
    50% {
        opacity: .7;
        color: var(--bs-light);
    }
}
